import React from 'react'
import useTranslation from 'next-translate/useTranslation'
import { ProfileInfoCover } from 'components/Merchant'
import Container from 'components/Container'
import { GetServerSideProps } from 'next'
import { PROFILE } from 'utils/data/profile/operations'
import {
  ProfileQuery,
  ProfileQueryVariables,
  ThumbnailSizeEnum,
  useProfileQuery,
} from 'src/generated/graphql-frontend'
import { initializeApollo } from 'utils/ApolloProvider'
import { NotFound, ProfilePage } from 'components/ProfilePage'
import { PageWrapper } from 'components/PageWrapper'

export const getServerSideProps: GetServerSideProps = async (context) => {
  const apolloClient = initializeApollo({})
  const username = context.query.username as string | undefined
  try {
    await apolloClient.query<ProfileQuery, ProfileQueryVariables>({
      query: PROFILE,
      variables: {
        username,
        size: ThumbnailSizeEnum.Medium,
        coverPictureSize: ThumbnailSizeEnum.CoverPicture,
      },
    })
    return {
      props: {
        initialApolloState: apolloClient.cache.extract(),
        username,
      },
    }
  } catch (error) {
    console.log('error.extraInfo')
    console.error(JSON.stringify(error))
    return {
      notFound: true,
      props: {
        initialApolloState: apolloClient.cache.extract(),
        username,
      },
    }
  }
}

// TODO: Fix when no cookie is set, not logged in request
// TODO: This is copy and paste of [page].tsx, maybe there is a better way
const Profile: React.FC<{ username: string }> = ({ username }) => {
  const { t } = useTranslation('username')
  const { data } = useProfileQuery({
    variables: {
      username,
      size: ThumbnailSizeEnum.Medium,
      coverPictureSize: ThumbnailSizeEnum.CoverPicture,
    },
  })
  const page = 'about'
  const profile = data?.profile
  return (
    <PageWrapper
      title={profile?.name || profile?.username || t('pageWrapperTitle')}
      description={profile?.description as string}
      image={profile?.profilePicture?.thumbnailSignedUrl as string}
      metaTitle={profile?.name || profile?.username}
    >
      {profile ? (
        <>
          <ProfileInfoCover profile={profile} />
          <Container>
            <ProfilePage profile={profile} defaultTabKey={page} />
          </Container>
        </>
      ) : (
        <NotFound username={username} />
      )}
    </PageWrapper>
  )
}

export default Profile
